.app {
  text-align: center;
}

.app-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

.content {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}
