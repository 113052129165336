.how-it-works {
    width: 45%;
    padding: 20px;
    text-align: left;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  .how-it-works h2 {
    margin-bottom: 20px;
  }
  
  .how-it-works ul {
    list-style: none;
    padding: 0;
  }
  
  .how-it-works li {
    margin: 10px 0;
  }
  
  .stats {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .stats div {
    text-align: center;
  }
  